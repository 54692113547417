import { Variant } from '../typings/base'
import { getCartSecondHandItems } from './cart'
import { getProductSizesFiltered } from './filters'
import { getChosenCondition } from './takeBackFiltersConditions'
import { filterSkus, getVariantsSkus } from './variants'

// get the lowest selected sku from filters or get the lowest available sku
export const getChosenSku = (
  variants: Variant[],
  secondHandStockSkus,
  secondHandStock,
  cart,
  setNoMoreStockAvailable
) => {
  //get all existing skus for this product
  const skus = getVariantsSkus(variants)
  // get all skus in stock for this product
  const availableSkusFilteredByStock = getAvailableSkus(
    skus,
    secondHandStockSkus
  )
  //get filtered sizes from local storage, if there is nothing, result = []
  const filterSizes = getProductSizesFiltered()
  //get skus filtered by stock and filter
  const filteredSkus = filterSkus(
    variants,
    availableSkusFilteredByStock,
    filterSizes
  )

  // get one prelected sku
  const preSelectedSku = getSizeToPreselected(
    filteredSkus,
    secondHandStock,
    cart
  )
  setNoMoreStockAvailable(false)
  // get second hand products information from cart => cartSecondHandItems type
  const secondHandInCart = getCartSecondHandItems(cart)
  const remainStockAfterCheckCart = getSecondHandStockRemainsCompareCart(
    secondHandStock,
    secondHandInCart
  )

  if (preSelectedSku === null) {
    //set le out of stock
    if (remainStockAfterCheckCart.length === 0) {
      setNoMoreStockAvailable(true)
    } else {
      setNoMoreStockAvailable(false)
      //else set the first size available if there is no more filtered siz available
      return remainStockAfterCheckCart[0].sku
    }
  }
  return preSelectedSku
}

export const getAvailableSkus = (allSkus, skusInStock) => {
  return allSkus.filter((sku) => skusInStock.includes(sku))
}

export const getSizeToPreselected = (filteredSkus, secondHandStock, cart) => {
  for (let index = 0; index < filteredSkus.length; index++) {
    const chosenCondition = getChosenCondition(
      filteredSkus[index],
      secondHandStock,
      cart
    )
    if (chosenCondition !== null && chosenCondition.length > 0)
      return filteredSkus[index]
  }
  return null
}

// get second hand stock remains after remove second hand put in cart
export const getSecondHandStockRemainsCompareCart = (
  secondHandStock,
  secondHandInCart
) => {
  const remainStock = []
  for (let index = 0; index < secondHandStock.length; index++) {
    //get second hand products in cart according sku
    const secondHandInCartBySku = secondHandInCart.filter(
      (inCart) => inCart.sku === secondHandStock[index].lizeeSKU
    )
    const productsInCartBySku =
      secondHandInCartBySku.length > 0 ? secondHandInCartBySku[0].units : []
    //get second hand stock according sku
    const secondHandStockBySku = secondHandStock[index].secondHand
    const restInStockForThisSku = []
    if (productsInCartBySku !== undefined) {
      secondHandStockBySku.map((stock) => {
        const exists = productsInCartBySku.filter(
          (productInCartBySku) =>
            productInCartBySku.productState === stock.quality
        )
        const numberAvailable = stock.quantity - exists.length
        if (numberAvailable > 0) {
          restInStockForThisSku.push({
            quality: stock.quality,
            quantity: numberAvailable,
          })
        }
      })
      const secondHandInformations = [...restInStockForThisSku]
      if (secondHandInformations.length > 0) {
        remainStock.push({
          sku: secondHandStock[index].lizeeSKU,
          secondHand: secondHandInformations,
        })
      }
    }
  }

  return remainStock
}
