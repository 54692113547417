import React from 'react'
import { useRouter } from 'next/router'
import clsx from 'clsx'
import {
  Typography,
  Container,
  makeStyles,
  createStyles,
  useTheme,
  Theme,
  useMediaQuery,
} from '@material-ui/core'
import { isSubscription } from '../../utils/constants'
import { LayoutStyle } from '../../typings/base'

type Tprops = {
  title?: string
  topTitle?: string
  heroImg?: any
  taxonImg?: any
  heroSettings?: any
  isMainTitle?: boolean
  isDisplayAccordionOnMobile?: boolean
}

const useStyles = makeStyles<Theme, LayoutStyle>((theme) =>
  createStyles({
    root: {
      position: 'relative',
      backgroundColor: theme.extraColors.greyLight,
      backgroundRepeat: 'repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: (LayoutStyle) =>
        LayoutStyle?.isZwiterHandCategory ? 80 : 245,
      // minHeight: 245,
      [theme.breakpoints.down('sm')]: {
        zIndex: 1, //VS datepicker
      },
      [theme.breakpoints.up('sm')]: {
        minHeight: 250,
      },
    },
    heroShopAccordion: {
      minHeight: 118,
    },
    heroContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 60,
      [theme.breakpoints.up('sm')]: {
        minHeight: 200,
      },
    },
    heroTopTitle: {
      zIndex: 2,
    },
    heroTitle: {
      textAlign: 'center',
      zIndex: 2,
      color: theme.palette.common.black,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(18),
      },
    },
    media: {
      zIndex: 1,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      '&>img': {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    mediaOverlay: {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0.6,
      background:
        'linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.8) 42.42%, rgba(37, 24, 56, 0) 100%)',
    },
  })
)

const HeroShop = ({
  title,
  topTitle,
  heroImg,
  taxonImg,
  heroSettings,
  isMainTitle,
  isDisplayAccordionOnMobile,
}: Tprops) => {
  const router = useRouter()
  const category = router?.query?.slug
  const LayoutStyle = {
    isZwiterHandCategory: category?.length && category[0] === 'second-hand',
  }
  const classes = useStyles(LayoutStyle)
  const theme = useTheme()
  const heroBgColor = heroSettings?.hero_background_color
  const heroTextColor = heroSettings?.hero_text_color
  const heroOverlay = heroSettings?.hero_banner_overlay
  const useTaxonImage = heroSettings?.use_taxon_img
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const isDesktopXL = useMediaQuery(theme.breakpoints.up('xl'))

  return (
    <section
      className={clsx(
        `hero--shop ${classes.root}`,
        isMobile &&
          isSubscription &&
          isDisplayAccordionOnMobile &&
          classes.heroShopAccordion
      )}
      style={{ backgroundColor: heroBgColor }}
    >
      <Container
        className={clsx(classes.heroContainer, 'hero__title__container')}
      >
        {topTitle && topTitle.length > 0 && (
          <Typography
            variant="h4"
            component="p"
            className={`hero__toptitle ${classes.heroTopTitle}`}
            style={{ color: heroTextColor }}
          >
            {topTitle}
          </Typography>
        )}
        <Typography
          variant="h2"
          component={isMainTitle ? 'h1' : 'div'}
          className={`hero__title ${classes.heroTitle}`}
          style={{ color: heroTextColor }}
        >
          {title}
        </Typography>
        {!useTaxonImage && heroImg?.url !== undefined ? (
          <div className={classes.media}>
            {heroOverlay && (
              <div className={classes.mediaOverlay} id="mediaOverlay"></div>
            )}
            {isTablet &&
              (isMobile ? (
                <img src={(heroImg.small || heroImg.sm).url} alt={''} />
              ) : (
                <img src={(heroImg.medium || heroImg.md).url} alt={''} />
              ))}
            {isDesktop &&
              (isDesktopXL && !!heroImg.xl ? (
                <img src={heroImg.xl.url} alt={''} />
              ) : (
                <img src={heroImg.url} alt={''} />
              ))}
          </div>
        ) : (
          taxonImg?.cachedPath && (
            <div className={classes.media}>
              {heroOverlay && (
                <div className={classes.mediaOverlay} id="mediaOverlay"></div>
              )}
              <img src={taxonImg?.cachedPath} alt={''} />
            </div>
          )
        )}
      </Container>
    </section>
  )
}

export default HeroShop
