import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import CMSText from '../Global/CMSText'
import { PrismicTakeBackData } from '../../interfaces/common/prismicTakeBack'
import SecondHandBackInStockAlertModal from './SecondHandBackInStockAlertModal'
import { Variant } from '../../typings/base'
import Button from '../Button/Button'
import * as gtag from '../../utils/gtag'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backInStockAlertButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      paddingRight: theme.spacing(0.6),
    },
    backInStockAlertLink: {
      justifyContent: 'start',
      padding: theme.spacing(0),
      textDecoration: 'underline',
      textTransform: 'inherit',
      color: theme.palette.common.black,
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(1),
      },
      '&:hover, &:focus': {
        background: 'none',
      },
    },
  })
)

type Tprops = {
  prismicTakeback: PrismicTakeBackData
  allVariantsNotInStock: Variant[]
  displayTextBeforeButton?: boolean
}

const SecondHandBackInStockAlertButton = ({
  prismicTakeback,
  allVariantsNotInStock,
  displayTextBeforeButton = true,
}: Tprops) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { trackEvent } = useMatomo()
  const [openBackInStockAlertModal, setOpenBackInStockAlertModal] =
    useState(false)
  const handleCloseBackInStockAlert = () => {
    setOpenBackInStockAlertModal(false)
    trackEvent({
      category: 'Second Hand Back In Stock Alert',
      action: `User close stock alert modal`,
    })
    gtag.tracker('User close stock alert modal', {
      value: openBackInStockAlertModal,
    })
  }

  const handleOpenBackInStockAlert = () => {
    setOpenBackInStockAlertModal(true)
    trackEvent({
      category: 'Second Hand Back In Stock Alert',
      action: `User open stock alert modal`,
    })
    gtag.tracker('User open stock alert modal', {
      value: openBackInStockAlertModal,
    })
  }

  return (
    <div className={classes.backInStockAlertButtonContainer}>
      {displayTextBeforeButton && (
        <>
          <CMSText
            data={prismicTakeback?.take_back_stock_alert_text}
            defaultText={t('texts:takeBack:take_back_stock_alert_text')}
          />
          &nbsp;
        </>
      )}
      <Button
        variant="text"
        onClick={handleOpenBackInStockAlert}
        className={clsx(classes.backInStockAlertLink)}
      >
        <CMSText
          data={prismicTakeback?.take_back_stock_alert_link}
          defaultText={t('texts:takeBack:take_back_stock_alert_link')}
        />
      </Button>
      <SecondHandBackInStockAlertModal
        open={openBackInStockAlertModal}
        onClose={handleCloseBackInStockAlert}
        prismicTakeback={prismicTakeback}
        allVariantsNotInStock={allVariantsNotInStock}
      />
    </div>
  )
}

export default SecondHandBackInStockAlertButton
