import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  createStyles,
  Dialog,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import CMSText from '../Global/CMSText'
import { PrismicTakeBackData } from '../../interfaces/common/prismicTakeBack'
import { Variant } from '../../typings/base'
import Button from '../Button/Button'
import BackInStockAlertForm from './BackInStockAlertForm'
import router from 'next/router'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: 0,
      '& .MuiPaper-root': {
        borderRadius: theme.shape.borderRadius,
        [theme.breakpoints.up('md')]: {
          minWidth: 500,
        },
      },
    },
    modalHead: {
      padding: theme.spacing(1, 2),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: 0,
    },
    modalTitle: {
      display: 'inline-flex',
      alignItems: 'center',
      fontWeight: 700,
      lineHeight: 1,
      color: theme.palette.primary.main,
      '&>.MuiSvgIcon-root': {
        marginRight: theme.spacing(1),
      },
    },
    modalClose: {
      cursor: 'pointer',
      padding: theme.spacing(0.5),
      width: theme.spacing(3),
      height: theme.spacing(3),
      borderRadius: theme.spacing(2),
      textAlign: 'center',
      color: 'white',
      background: theme.palette.primary.main,
      '&:hover, &:focus': {
        background: theme.extraColors.grey,
      },
    },
    sizeButton: {
      height: theme.spacing(4),
      padding: theme.spacing(1, 2),
      margin: theme.spacing(0.5, 0.5, 0.5, 0),
      minWidth: 45,
      backgroundColor: theme.extraColors.greyLight,
      '&:hover': {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.5, 1),
        margin: theme.spacing(0.25),
      },
    },
    selected: {
      height: theme.spacing(4),
      padding: theme.spacing(1, 2),
      margin: theme.spacing(0.5, 0.5, 0.5, 0),
      minWidth: 45,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.black,
      '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.5, 1),
        margin: theme.spacing(0.25),
      },
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(0, 2, 2),
    },
    sizeContainer: {
      marginBottom: 10,
    },
  })
)

type Tprops = {
  onClose: () => void
  open: boolean
  prismicTakeback: PrismicTakeBackData
  allVariantsNotInStock: Variant[]
}

const SecondHandBackInStockAlertModal = ({
  onClose,
  open,
  prismicTakeback,
  allVariantsNotInStock,
}: Tprops) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [selectedSku, setSelectedSku] = useState('')

  const baseUrl =
    typeof window !== 'undefined' && window.location.origin
      ? window.location.origin
      : ''
  const currentFullUrl = baseUrl + router.asPath
  const selectedProducts = [
    {
      sku: selectedSku,
      quantity: 1,
    },
  ]
  return (
    <Dialog open={open} onClose={onClose} className={classes.modal}>
      <div className={classes.modalHead}>
        <Typography component={'h2'} className={classes.modalTitle}>
          <span>
            <CMSText
              data={prismicTakeback?.take_back_stock_alert_modal_title}
              defaultText={t(
                'texts:takeBack:take_back_stock_alert_modal_title'
              )}
            />
          </span>
        </Typography>
        <CloseIcon onClick={onClose} className={classes.modalClose} />
      </div>
      <div className={classes.modalContent}>
        <div>
          <CMSText
            data={prismicTakeback?.take_back_stock_alert_modal_text}
            defaultText={t('texts:takeBack:take_back_stock_alert_modal_text')}
          />
        </div>
        <div className={classes.sizeContainer}>
          <Typography component={'div'}>
            <CMSText
              asText
              data={prismicTakeback?.take_back_stock_alert_modal_size_label}
              defaultText={t(
                'texts:takeBack:take_back_stock_alert_modal_size_label'
              )}
            />
          </Typography>
          {/**get variant.sku to email alert */}
          <div>
            {allVariantsNotInStock?.map((variant, index) => {
              return (
                <Button
                  key={index}
                  size="small"
                  className={
                    variant.sku === selectedSku
                      ? `sizes__btn selected ${classes.selected}`
                      : `sizes__btn ${classes.sizeButton}`
                  }
                  onClick={() => setSelectedSku(variant.sku)}
                >
                  {variant.size}
                </Button>
              )
            })}
          </div>
        </div>
        <BackInStockAlertForm
          selectedProducts={selectedProducts}
          url={currentFullUrl}
          isSecondHand
        />
      </div>
    </Dialog>
  )
}

export default SecondHandBackInStockAlertModal
