import { Products, Variant } from '../typings/base'
import { QualityArray } from '../typings/secondHandBase'
import { SecondHandStockResponse } from '../typings/secondHandStockResponse'
import { getCartSecondHandItems } from './cart'
import { getChosenCondition } from './takeBackFiltersConditions'
import {
  getChosenSku,
  getSecondHandStockRemainsCompareCart,
} from './takeBackFiltersSize'
import { getCodeBySku } from './variants'

// return the number of product in cart according to sku and quality
export const currentQualityInCart = (quality, sku, cart): number => {
  let numberInCart = 0
  cart?.item?.items.map((products) => {
    products.units.map((product) => {
      if (
        product.productCondition === quality &&
        products.product.variants[0].sku === sku
      ) {
        numberInCart += 1
      }
    })
  })
  return numberInCart
}
// get informations about all available qualities for the preselected size in order to display them
export const createProductsQualityArrayBySize = (
  takeBackState,
  sku,
  states,
  cart
): QualityArray[] => {
  const qualitiesArray = []
  const currentSkuQualities = takeBackState?.find(
    (varSku) => varSku.lizeeSKU === sku
  )?.productsByQuality
  const skuStateKeys = Object.keys(states)

  skuStateKeys.forEach((skuStateKey) => {
    const currentProducts = currentSkuQualities?.find(
      (quality) => quality.quality === skuStateKey
    )?.products
    // a product exist within the quality
    const numberInCart = currentQualityInCart(skuStateKey, sku, cart)

    qualitiesArray.push({
      title: states[skuStateKey]?.title,
      quality: skuStateKey,
      products: currentProducts,
      disabled:
        typeof currentProducts === 'undefined' ||
        numberInCart >= currentProducts.length,
    })
  })
  return qualitiesArray
}

// return the original price for current product
export const handleTakeBackOriginalPrice = (
  currentItem: Products,
  currentSku
) => {
  const originalPrice = Object.values(currentItem.variants)?.find(
    (el) => el.sku === currentSku
  )?.originalPrice.current

  return originalPrice
}

// get all informations related to the chosen sku and chosen quality
export const getChosenQualityObject = (chosenQuality, qualitiesArray) => {
  const chosenQualityObject = qualitiesArray.find(
    (qualityObject) => qualityObject.quality === chosenQuality
  )
  return chosenQualityObject
}

//set current item 2nde hand images
export const setCurrentItemImagesAndGetDescription = (
  chosenQualityObject,
  chosenSku,
  cart,
  currentItem
) => {
  const numberInCart = currentQualityInCart(
    chosenQualityObject?.quality,
    chosenSku,
    cart
  )
  const secondHandDescription =
    chosenQualityObject?.products !== undefined &&
    chosenQualityObject?.products[numberInCart]?.note
  const images = []

  if (chosenQualityObject !== undefined) {
    images.push(...chosenQualityObject?.products[numberInCart]?.images)
  }

  images.push(...currentItem.images)

  currentItem.images = images

  return {
    item: currentItem,
    secondHandDescription: secondHandDescription,
  }
}

export const getSecondHandPrice = (
  item: Products,
  chosenQualityObject,
  chosenSku
) => {
  const currentPricesByCondition = Object.values(item.variants)?.find(
    (el) => el.sku === chosenSku
  )?.resalePrice
  const price =
    currentPricesByCondition !== undefined &&
    currentPricesByCondition[chosenQualityObject?.quality]
  return price
}

//return preselected sku and condition and all informations according to this 2 parameters (ex: price, current images)
export const preSelectedSkuAndCondition = (
  secondHandStock,
  currentItem: Products,
  takeBackState: any,
  states: any,
  cart,
  setNoMoreStockAvailable
) => {
  const secondHandStockSkus = secondHandStock.map(
    (secondHandStockItem) => secondHandStockItem.lizeeSKU
  )

  const chosenSku = getChosenSku(
    currentItem?.variants,
    secondHandStockSkus,
    secondHandStock,
    cart,
    setNoMoreStockAvailable
  )

  const qualitiesArray = createProductsQualityArrayBySize(
    takeBackState,
    chosenSku,
    states,
    cart
  )

  const originalPrice = handleTakeBackOriginalPrice(currentItem, chosenSku)

  const noMoreFilterAvailable = qualitiesArray.find(
    (quality) => !quality.disabled
  )?.quality

  const chosenQuality =
    getChosenCondition(chosenSku, secondHandStock, cart) !== null
      ? getChosenCondition(chosenSku, secondHandStock, cart)
      : noMoreFilterAvailable

  const chosenCode = getCodeBySku(chosenSku, currentItem?.variants)

  const chosenQualityObject = getChosenQualityObject(
    chosenQuality,
    qualitiesArray
  )

  const currentItemAndDescription = setCurrentItemImagesAndGetDescription(
    chosenQualityObject,
    chosenSku,
    cart,
    currentItem
  )

  const secondHandCurrentPrice = getSecondHandPrice(
    currentItemAndDescription?.item,
    chosenQualityObject,
    chosenSku
  )

  return {
    chosenSku: chosenSku,
    chosenCode: chosenCode,
    chosenQuality: chosenQuality,
    originalPrice: originalPrice,
    qualitiesArray: qualitiesArray,
    chosenQualityObject: chosenQualityObject,
    item: currentItemAndDescription?.item,
    secondHandDescription: currentItemAndDescription?.secondHandDescription,
    currentPrice: secondHandCurrentPrice,
  }
}

// return an array of available skus after remove quantity already in cart
export const getRemainSkusAvailableAfterCheckCart = (cart, secondHandStock) => {
  const secondHandInCart = getCartSecondHandItems(cart)
  const remainStockAfterCheckCart = getSecondHandStockRemainsCompareCart(
    secondHandStock,
    secondHandInCart
  )
  return remainStockAfterCheckCart.map((stockBySku) => stockBySku.sku)
}

export const handlePreselectedSizeAndCondition = (
  secondHandStock,
  productToChangeForPreselection,
  takeBackStockPreLoaded,
  states,
  cart,
  setOriginalPrice,
  setCurrentVariantCode,
  setCurrentSizeQualities,
  setShowQualities,
  setSelectedQuality,
  setCurrentSku,
  setCurrentItem,
  setCurrentSecondHandDescription,
  setTakeBackPrice,
  setGliderReady,
  setNoMoreStockAvailable,
  setRemainSecondHandSkusAfterCheckCart
) => {
  const ChosenSizeAndCondition = preSelectedSkuAndCondition(
    secondHandStock,
    productToChangeForPreselection,
    takeBackStockPreLoaded,
    states,
    cart,
    setNoMoreStockAvailable
  )
  if (
    ChosenSizeAndCondition?.chosenSku !== null &&
    ChosenSizeAndCondition.chosenQuality !== undefined
  ) {
    // use the same steps as handleSizeSelected and handleStateSelected but with preloaded take back data
    setOriginalPrice(ChosenSizeAndCondition?.originalPrice)
    setCurrentVariantCode(ChosenSizeAndCondition?.chosenCode)
    setCurrentSizeQualities(ChosenSizeAndCondition?.qualitiesArray)
    setShowQualities(true)
    // in case of there is no more size and state filtered, we take the first state available according to preselected sku
    setSelectedQuality(
      ChosenSizeAndCondition.chosenQuality !== null
        ? ChosenSizeAndCondition.chosenQuality
        : ChosenSizeAndCondition?.qualitiesArray.find(
            (quality) => !quality.disabled
          ).quality
    )
    setCurrentSku(ChosenSizeAndCondition?.chosenSku)
    setCurrentItem(ChosenSizeAndCondition?.item)
    setCurrentSecondHandDescription(
      ChosenSizeAndCondition?.secondHandDescription
    )
    setTakeBackPrice(ChosenSizeAndCondition?.currentPrice)
    if (setGliderReady) setGliderReady(true)
    setRemainSecondHandSkusAfterCheckCart(
      getRemainSkusAvailableAfterCheckCart(cart, secondHandStock)
    )
  } else {
    if (setGliderReady) setGliderReady(true)
    setNoMoreStockAvailable(true)
  }
}

export const getSecondHandImageForRelatedProduct = (
  relatedItemSkus: string[],
  currentItemAndAssociationTBStock: SecondHandStockResponse[]
) => {
  const skusInStock = currentItemAndAssociationTBStock.filter(
    (item) => item.productsByQuality.length > 0
  )

  const relatedItemSkusInStock = skusInStock.filter((TBStock) =>
    relatedItemSkus.includes(TBStock.lizeeSKU)
  )

  return relatedItemSkusInStock[0]?.productsByQuality[0]?.products[0]?.images
}
