import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useSelector } from 'react-redux'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import TextField from '../TextField/TextField'
import CMSText, { prismicText } from '../Global/CMSText'
import Button from '../Button/Button'
import { PrismicProductData } from '../../interfaces/common/prismicProduct'
import { shortProduct } from '../../typings/base'
import { postStockAlert } from '../../redux/actions/products'
import { RootState } from '../../redux/reducers/rootReducer'
import { isSubscription } from '../../utils/constants'
import * as gtag from '../../utils/gtag'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    actionsContainer: {
      display: 'flex',
      margin: '0 0.8rem 0.5rem',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    textField: {
      flex: 1,
      marginBottom: 0,
      '& input': {
        border: `1px solid ${theme.palette.primary.main}`,
        padding: '0.8rem 0.8rem',
        backgroundColor: theme.palette.common.white,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '0.8rem',
        width: '100%',
      },
    },
    button: {
      flex: 1,
      marginLeft: '2rem',
      height: '2.5rem',
      background: theme.palette.primary.main,
      marginTop: '0.1rem',
      '&:hover, &:active': {
        border: `1px solid ${theme.palette.primary.main}`,
        '&>span': {
          color: theme.palette.primary.main,
          transition: '0.5s',
        },
        transition: '0.5s',
      },
      '&:disabled': {
        background: theme.extraColors.greyMedium,
        cursor: 'not-allowed',
      },
      '&>span': {
        color: theme.palette.common.white,
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        width: '100%',
        padding: '0.8rem',
        '&>span': { fontSize: '85%' },
      },
    },
    message: {
      margin: '1rem 0 1rem 0.8rem',
      wordBreak: 'keep-all',
      [theme.breakpoints.down('sm')]: {
        '&>p': { fontSize: '85%' },
      },
    },
    errorMessage: {
      color: theme.palette.error.main,
    },
    failMessage: {
      color: theme.palette.warning.main,
    },
    validationMessage: {
      color: theme.palette.success.main,
      margin: '0 0 1rem 0.8rem',
      wordBreak: 'keep-all',
      [theme.breakpoints.down('sm')]: {
        '&>p': { fontSize: '85%' },
      },
    },
  })
)

type Tprops = {
  selectedProducts: shortProduct[]
  url: any
  stores: any
  isSecondHand?: boolean
  postStockAlert?: (data: {
    email: string
    products: shortProduct[]
    url: string
    warehouseId?: number
    isSecondHand?: boolean
  }) => Promise<any>
}

const BackInStockAlertForm = ({
  selectedProducts,
  url,
  stores,
  isSecondHand,
  postStockAlert,
}: Tprops) => {
  const { t } = useTranslation()
  const { trackEvent } = useMatomo()
  const classes = useStyles()
  const prismicProduct: PrismicProductData = useSelector(
    (state) => state.prismic.prismicProduct
  )

  const [mail, setMail] = useState('')
  const [isMailGood, setIsMailGood] = useState(true)
  const [showMessage, setShowMessage] = useState(false)
  const [showFailMessage, setShowFailMessage] = useState(false)
  const [mailAlreadyDone, setMailAreadyDone] = useState(false)

  const customContainerClass = isSubscription
    ? classes.actionsContainer
    : classes.formContainer

  const errorMessage = (
    <Typography
      variant="body1"
      component="p"
      className={clsx(
        'backInStockAlert__errorMessage',
        classes.errorMessage,
        classes.message
      )}
    >
      <CMSText
        asText
        data={prismicProduct?.stock_alert_mail_error}
        defaultText={t(
          'texts:single_product:backInStock:stock_alert_mail_error'
        )}
      />
    </Typography>
  )

  const failMessage = (
    <Typography
      variant="body1"
      component="p"
      className={clsx(
        'backInStockAlert__failMessage',
        classes.failMessage,
        classes.message
      )}
    >
      <CMSText
        asText
        data={
          mailAlreadyDone
            ? prismicProduct?.stock_alert_already_fail
            : prismicProduct?.stock_alert_validation_fail
        }
        defaultText={
          mailAlreadyDone
            ? t('texts:single_product:backInStock:stock_alert_already_fail')
            : t('texts:single_product:backInStock:stock_alert_validation_fail')
        }
      />
    </Typography>
  )

  const validationMessage = (
    <Typography
      variant="body1"
      component="p"
      className={clsx(
        'backInStockAlert__validationMessage',
        classes.validationMessage,
        classes.message
      )}
    >
      <CMSText
        asText
        data={prismicProduct?.stock_alert_validation_message}
        defaultText={t(
          'texts:single_product:backInStock:stock_alert_validation_message'
        )}
      />
    </Typography>
  )

  const warehouseId = stores?.selectedStore?.warehouseId
    ? stores?.selectedStore?.warehouseId
    : process.env.CUSTOM_WAREHOUSE
    ? process.env.CUSTOM_WAREHOUSE
    : null

  useEffect(() => {
    if (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(mail) === false && mail !== '')
      setIsMailGood(false)
    else setIsMailGood(true)
  }, [mail])

  const handleValidateAlert = async () => {
    const controller = new AbortController()
    // This case is made for the clients who have only one warehouse
    // Because for them, warehouseId will be null
    const data = warehouseId
      ? {
          email: mail,
          products: selectedProducts,
          url: url,
          warehouseId: warehouseId,
          isSecondHand,
        }
      : {
          email: mail,
          products: selectedProducts,
          url: url,
          isSecondHand,
        }
    trackEvent({
      category: 'Products',
      action: `Stock alert`,
      name: mail,
    })
    setShowFailMessage(false)
    setShowMessage(false)
    setMailAreadyDone(false)
    const response = await postStockAlert(data)
    if (response.type === 'POST_STOCK_ALERT_SUCCESS') {
      setShowMessage(true)
      if (data.isSecondHand) {
        trackEvent({
          category: 'Second Hand back in stock alert',
          action: `User subscribe to stock alert for sku n° ${selectedProducts[0].sku}`,
        })
        gtag.tracker(
          `User subscribe to stock alert for sku n° ${selectedProducts[0].sku}`,
          {
            value: selectedProducts[0].sku,
          }
        )
      }
    }

    if (response.type === 'POST_STOCK_ALERT_FAILURE') {
      const data = response?.err?.response?.data
      if (
        data?.message ===
        'Stock alert already exists for this email and these products'
      ) {
        setMailAreadyDone(true)
      }
      setShowFailMessage(true)
    }
    return controller.abort()
  }

  return (
    <>
      {showMessage ? (
        validationMessage
      ) : (
        <>
          <div
            className={clsx(
              customContainerClass,
              'backInStockAlert__actionsContainer'
            )}
          >
            <TextField
              className={clsx('backInStockAlert__textField', classes.textField)}
              type="email"
              value={mail}
              placeholder={prismicText(
                prismicProduct?.stock_alert_input_placeholder,
                t(
                  'texts:single_product:backInStock:stock_alert_input_placeholder'
                )
              )}
              onChange={(value: string) => {
                setMail(value)
              }}
            />
            <Button
              className={clsx('backInStockAlert__button', classes.button)}
              onClick={handleValidateAlert}
              color="primary"
              disabled={
                !isMailGood ||
                mail === '' ||
                selectedProducts[0].sku.length === 0
              }
            >
              <div>
                <CMSText
                  asText
                  data={prismicProduct?.stock_alert_button}
                  defaultText={t(
                    'texts:single_product:backInStock:stock_alert_button'
                  )}
                />
              </div>
            </Button>
          </div>
          <div>
            {!isMailGood && errorMessage}
            {showFailMessage && failMessage}
          </div>
        </>
      )}
    </>
  )
}

const mapDispatchToProps = {
  postStockAlert: postStockAlert,
}

const mapStateToProps = (state: RootState) => ({
  stores: state.stores,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BackInStockAlertForm)
