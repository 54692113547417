import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { createStyles, makeStyles, Typography } from '@material-ui/core'
import CMSText from '../Global/CMSText'
import { shortProduct } from '../../typings/base'
import { postStockAlert } from '../../redux/actions/products'
import BackInStockAlertForm from './BackInStockAlertForm'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      borderRadius: '0.5rem',
      paddingBottom: '0.7rem',
      margin: '1rem 0',
      background: theme.extraColors.greyLight,
      display: 'flex',
      flexDirection: 'column',
      '&>.MuiButton-root>.Mui-disabled': {
        background: theme.extraColors.greyMedium,
      },
    },
    titleContainer: {
      margin: '0.5rem 0 0 0.8rem',
      '&>ul': {
        listStyle: 'none',
        margin: 0,
        padding: 0,
        '&>li:': {
          '&:before': {
            content: '"•"',
            marginRight: '0.2rem',
            fontSize: '1.3rem',
            color: theme.palette.warning.main,
            display: 'inline-block',
          },
        },
      },
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      wordBreak: 'keep-all',
      '&>p': {
        fontWeight: 700,
      },
      [theme.breakpoints.down('sm')]: {
        '&>p': { fontSize: '90%' },
      },
    },
    text: {
      margin: '0 0.5rem 1rem 0.8rem',
      wordBreak: 'keep-all',
      [theme.breakpoints.down('sm')]: {
        '&>p': { fontSize: '85%' },
      },
    },
    validationMessage: {
      color: theme.palette.success.main,
      margin: '0 0 1rem 0.8rem',
      wordBreak: 'keep-all',
      [theme.breakpoints.down('sm')]: {
        '&>p': { fontSize: '85%' },
      },
    },
    message: {
      margin: '1rem 0 1rem 0.8rem',
      wordBreak: 'keep-all',
      [theme.breakpoints.down('sm')]: {
        '&>p': { fontSize: '85%' },
      },
    },
    errorMessage: {
      color: theme.palette.error.main,
    },
    failMessage: {
      color: theme.palette.warning.main,
    },
    actionsContainer: {
      display: 'flex',
      margin: '0 0.8rem 0.5rem',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    textField: {
      flex: 1,
      marginBottom: 0,
      '& input': {
        border: `1px solid ${theme.palette.primary.main}`,
        padding: '0.8rem 0.8rem',
        backgroundColor: theme.palette.common.white,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '0.8rem',
        width: '100%',
      },
    },
    button: {
      flex: 1,
      marginLeft: '2rem',
      height: '2.5rem',
      background: theme.palette.primary.main,
      marginTop: '0.1rem',
      '&:hover, &:active': {
        border: `1px solid ${theme.palette.primary.main}`,
        '&>span': {
          color: theme.palette.primary.main,
          transition: '0.5s',
        },
        transition: '0.5s',
      },
      '&:disabled': {
        background: theme.extraColors.greyMedium,
        cursor: 'not-allowed',
      },
      '&>span': {
        color: theme.palette.common.white,
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        width: '100%',
        padding: '0.8rem',
        '&>span': { fontSize: '85%' },
      },
    },
  })
)

const mapDispatchToProps = {
  postStockAlert: postStockAlert,
}

type Tprops = {
  singleProductPrismicData: any
  products: shortProduct[]
  url: string
  warehouseId: number
  postStockAlert?: (data: {
    email: string
    products: shortProduct[]
    url: string
    warehouseId?: number
  }) => Promise<any>
  isPack?: boolean
}

const BackInStockAlert = ({
  singleProductPrismicData,
  products,
  url,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  warehouseId,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  postStockAlert,
  isPack,
}: Tprops) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={clsx('backInStockAlert', classes.root)}>
      <div
        className={clsx(
          'backInStockAlert__titleContainer',
          classes.titleContainer
        )}
      >
        <ul>
          <li className={clsx('backInStockAlert__title', classes.title)}>
            <Typography variant="h5" component="p">
              {isPack ? (
                <CMSText
                  asText
                  data={singleProductPrismicData?.stock_alert_title_pack}
                  defaultText={t(
                    'texts:single_product:backInStock:stock_alert_title_pack'
                  )}
                />
              ) : (
                <CMSText
                  asText
                  data={singleProductPrismicData?.stock_alert_title}
                  defaultText={t(
                    'texts:single_product:backInStock:stock_alert_title'
                  )}
                />
              )}
            </Typography>
          </li>
        </ul>
      </div>
      <div className={clsx('backInStockAlert__text', classes.text)}>
        <Typography variant="body1" component="p">
          <CMSText
            asText
            data={singleProductPrismicData?.stock_alert_text}
            defaultText={t('texts:single_product:backInStock:stock_alert_text')}
          />
        </Typography>
      </div>
      <BackInStockAlertForm selectedProducts={products} url={url} />
    </div>
  )
}

export default connect(null, mapDispatchToProps)(BackInStockAlert)
