import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { prismicText } from '../Global/CMSText'

const useStyles = makeStyles(() =>
  createStyles({
    buyAbleMention: {
      fontWeight: 400,
      fontSize: '80%',
    },
  })
)
type TProps = {
  buyAbleMention: []
}

const BuyAbleMention = ({ buyAbleMention }: TProps) => {
  const classes = useStyles()
  const mention = prismicText(buyAbleMention)

  return mention?.length > 0 ? (
    <span className={clsx('buyAbleMention', classes.buyAbleMention)}>
      {mention}
    </span>
  ) : null
}

export default BuyAbleMention
