/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react'
import {
  makeStyles,
  createStyles,
  Theme,
  Dialog,
  Typography,
  useMediaQuery,
  useTheme,
  Grid,
  DialogContent,
} from '@material-ui/core'
import Button from '../Button/Button'
import CloseIcon from '@material-ui/icons/Close'
import { Cart, Product } from '../../typings/base'
import SizeCard from './SizeCard'
import { KeyboardArrowLeft } from '@material-ui/icons'
import CMSText from '../Global/CMSText'
import { useTranslation } from 'react-i18next'
import { PrismicProduct } from '../../interfaces/common/prismicProduct'
import { TakeBackReducerState } from '../../redux/reducers/takeBackReducer'
import { PrismicGeneralData } from '../../interfaces/common/prismicGeneralConfig'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiDialog-paper': {
        borderRadius: 0,
      },
    },
    closeButton: {
      display: 'inline-flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: theme.extraColors.greyLight,
    },
    cross: {
      cursor: 'pointer',
      height: 32,
      width: 32,
      padding: theme.spacing(0.5),
      color: theme.palette.common.white,
      backgroundColor: theme.extraColors.grey,
      borderRadius: '50%',
      margin: theme.spacing(1),
    },
    titleInHeader: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
    dialogFooter: {
      height: 60,
      backgroundColor: theme.extraColors.greyLight,
      display: 'inline-flex',
      justifyContent: 'space-between',
      position: 'sticky',
      bottom: 0,
      zIndex: 3,
    },
    footerButtons: {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(1),
      justifyContent: 'center',
    },
    buttonfoot: {
      padding: theme.spacing(1, 2),
    },
    buttonfootBack: {
      padding: 0,
      color: theme.palette.common.black,
      textDecoration: 'underline',
      textTransform: 'none',
      background: 'none',
      '&:hover, &:focus': {
        textDecoration: 'none',
        background: 'none',
      },
    },
  })
)

type Tprops = {
  open?: boolean
  handleClose?: () => void
  AddMultipleProductsToCart?: any
  products: Product
  text?: any
  stock?: any
  type?: string
  onQuantityChange?: (code: string, name: string, quantity: number) => void
  cart: Cart
  thumbRatio: string
  thumbWidth?: number
  isMobile?: boolean
  settings: PrismicGeneralData
  currentUrl?: string
  selection?: any
  setSelection?: any
  isSecondHandTaxon?: boolean
  takeback: any
  takeBackLP: TakeBackReducerState
  productSettings?: PrismicProduct
}

const SizeModal = ({
  open,
  handleClose,
  products,
  text,
  stock,
  onQuantityChange,
  AddMultipleProductsToCart,
  type,
  cart,
  thumbRatio,
  thumbWidth,
  isMobile,
  settings,
  currentUrl,
  selection,
  setSelection,
  isSecondHandTaxon,
  takeback,
  productSettings,
}: Tprops) => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handlePackSelection = (
    isSimple,
    productCode,
    variantCode,
    quantity
  ) => {
    let id = null
    selection?.length > 0 &&
      selection?.map((select, index) => {
        if (select.productCode === productCode) {
          id = index
        }
      })
    let productToAdd = {}
    if (isSimple) {
      productToAdd = {
        quantity: quantity,
        productCode: productCode,
      }
    } else {
      productToAdd = {
        quantity: quantity,
        variantCode: variantCode,
        productCode: productCode,
      }
    }

    if (id !== null) {
      const newArr = [...selection]
      if (quantity > 0) {
        // if quantity selected by customer is > 0 we add the product in our array
        newArr[id] = productToAdd
      } else {
        // else we remove the row in our array
        newArr.splice(
          newArr.findIndex((item) => item.productCode === productCode),
          1
        )
      }

      // we update the selection with the new array
      setSelection(newArr)
    } else {
      setSelection && setSelection((selection) => [...selection, productToAdd])
    }
  }

  return (
    <>
      <Dialog
        className={
          type === 'pack'
            ? `size-modal size-modal--pack ${classes.root}`
            : `size-modal size-modal--product ${classes.root}`
        }
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        maxWidth={type === 'pack' ? 'lg' : 'md'}
        scroll={'paper'}
      >
        <div className={`size-modal__head ${classes.closeButton}`}>
          <Typography
            variant="h5"
            component="h2"
            className={`size-modal__title ${classes.titleInHeader}`}
          >
            {type !== 'pack' ? (
              <CMSText
                asText
                data={text?.data?.title_size_modale}
                defaultText={t('texts:shop:title_size_modale')}
              />
            ) : (
              <CMSText
                asText
                data={text?.title_size_modale_pack}
                defaultText={t('texts:packs:title_size_modale')}
              />
            )}
          </Typography>
          <CloseIcon
            data-testid={'closeModaleButton'}
            className={`size-modal__close ${classes.cross}`}
            onClick={handleClose}
          />
        </div>
        <DialogContent dividers className={'size-modal__content'}>
          <Grid container>
            {/** packs */}
            {Array.isArray(products) ? (
              products?.map((product, index) => {
                return (
                  <Grid
                    sm={products.length === 1 ? 12 : 6}
                    xs={12}
                    item
                    key={index}
                  >
                    <SizeCard
                      product={product.product}
                      productsQty={product?.packProductQuantity}
                      text={text}
                      stock={stock}
                      onQuantityChange={onQuantityChange}
                      handlePackSelection={handlePackSelection}
                      cart={cart}
                      type={type}
                      thumbRatio={thumbRatio}
                      thumbWidth={thumbWidth}
                      settings={settings}
                      currentUrl={currentUrl}
                      productSettings={productSettings}
                      takeback={takeback}
                      isSecondHandTaxon={isSecondHandTaxon}
                      addedToCart={handleClose}
                    />
                  </Grid>
                )
              })
            ) : (
              <Grid item xs={12}>
                <SizeCard
                  product={products}
                  text={text}
                  stock={stock}
                  onQuantityChange={onQuantityChange}
                  handlePackSelection={handlePackSelection}
                  cart={cart}
                  type={type}
                  thumbRatio={thumbRatio}
                  thumbWidth={thumbWidth}
                  settings={settings}
                  currentUrl={currentUrl}
                  isSecondHandTaxon={isSecondHandTaxon}
                  productSettings={productSettings}
                  takeback={takeback}
                  addedToCart={handleClose}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        {type === 'pack' && (
          <div className={`size-modal__foot ${classes.dialogFooter}`}>
            <div className={`size-modal__btns ${classes.footerButtons}`}>
              <Button
                onClick={handleClose}
                color="primary"
                variant={'text'}
                size={isMobile ? 'small' : 'medium'}
                className={`${classes.buttonfootBack} ${classes.buttonfoot}`}
                startIcon={<KeyboardArrowLeft />}
              >
                <CMSText
                  asText
                  data={text?.pack_size_modal_cta_return}
                  defaultText={t('texts:shop:pack_size_modal_cta_return')}
                />
              </Button>
            </div>
            <div
              className={`size-modal__btns pack-add-to-cart ${classes.footerButtons}`}
            >
              <Button
                onClick={() => AddMultipleProductsToCart(selection)}
                color="secondary"
                variant={'contained'}
                size={isMobile ? 'small' : 'medium'}
                className={classes.buttonfoot}
                data-testid={'pack-add-to-cart'}
                disabled={selection?.length === 0 || cart.loading}
              >
                <CMSText
                  asText
                  data={text?.pack_size_modal_cta_add}
                  defaultText={t('texts:shop:pack_size_modal_cta_add')}
                />
              </Button>
            </div>
          </div>
        )}
      </Dialog>
    </>
  )
}

export default SizeModal
