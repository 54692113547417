import { Filter } from '../typings/filters'

export const hasFiltersExist = (): boolean => {
  return typeof window !== 'undefined' && !!localStorage.getItem('Filters')
}

export const getFilters = (): Filter[] | [] => {
  if (!hasFiltersExist()) return []
  return JSON.parse(localStorage.getItem('Filters'))
}

export const isProductConditionFiltered = () => {
  return getProductConditionsFiltered().length > 0
}

export const getProductConditionsFiltered = (): string[] | [] => {
  const filters = getFilters()
  if (filters.length === 0) return []
  const productConditions = filters.find(
    (filter: Filter) => filter?.code === 'productCondition'
  )
  if (productConditions === undefined) return []
  return productConditions.values
}

export const getLowestConditionByConditions = (conditions): string => {
  if (conditions.includes('goodCondition')) return 'goodCondition'
  if (conditions.includes('veryGoodCondition')) return 'veryGoodCondition'
  if (conditions.includes('perfectCondition')) return 'perfectCondition'
}

export const isProductSizesFiltered = (): boolean => {
  return getProductSizesFiltered().length > 0
}

export const getProductSizesFiltered = (): string[] | [] => {
  const filters = getFilters()
  if (filters.length === 0) return []
  const productSizes = filters.find((filter: Filter) => filter.code === 'size')
  if (productSizes === undefined) return []
  return productSizes.values
}
