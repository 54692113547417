import { getCartSecondHandItems, getCurrentSkuProductInCart } from './cart'
import {
  getLowestConditionByConditions,
  getProductConditionsFiltered,
  isProductConditionFiltered,
} from './filters'

// get the lowest selected condition from filters or get the lowest available condition
export const getChosenCondition = (chosenSku, secondHandStock, cart) => {
  let currentSkuProductInCart
  let currentAvailableStock = []
  let currentAvailableConditions = []

  const availableSecondHandProduct = getAvailableSecondHandProductBySize(
    chosenSku,
    secondHandStock
  )

  if (!availableSecondHandProduct) return []
  const availableSecondHandProductsConditions =
    availableSecondHandProduct.secondHand

  // available conditions in stock by sku
  currentAvailableConditions = availableSecondHandProductsConditions.map(
    (conditionInStock) => conditionInStock.quality
  )

  const secondHandItemsInCart = getCartSecondHandItems(cart)

  if (secondHandItemsInCart?.length > 0) {
    // check if this sku is in cart
    currentSkuProductInCart = getCurrentSkuProductInCart(
      secondHandItemsInCart,
      chosenSku
    )
    if (currentSkuProductInCart.length > 0) {
      // check which conditions are in cart and compare with stock
      availableSecondHandProductsConditions.map((availableSecondHand) => {
        const exists = currentSkuProductInCart[0]?.units.filter(
          (unit) => unit.productState === availableSecondHand.quality
        )
        // remove quantity in cart in order to get remain available stock
        const quantityAvailable = availableSecondHand.quantity - exists.length
        currentAvailableStock.push({
          quality: availableSecondHand.quality,
          quantity: quantityAvailable,
        })
        currentAvailableStock = currentAvailableStock.filter(
          (currentItem) => currentItem.quantity > 0
        )
      })
      currentAvailableConditions = currentAvailableStock.map(
        (AvailableStock) => AvailableStock.quality
      )
    }
  }
  const availablefilteredConditions = getAvailableFilteredConditions(
    currentAvailableConditions
  )

  if (availablefilteredConditions.length === 0) return null
  return getLowestConditionByConditions(availablefilteredConditions)
}

// get all qualities available for the selected sku
export const getAvailableSecondHandProductBySize = (sku, secondHandStock) => {
  const availableQualitiesForSize = secondHandStock.find(
    (secondHandStockItem) => secondHandStockItem.lizeeSKU === sku
  )
  return availableQualitiesForSize
}
export const getAvailableFilteredConditions = (currentAvailableConditions) => {
  if (!isProductConditionFiltered()) return currentAvailableConditions
  const filteredConditions = getProductConditionsFiltered()
  return filteredConditions.filter((filteredCondition) =>
    currentAvailableConditions.includes(filteredCondition)
  )
}
