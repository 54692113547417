import { makeStyles, createStyles, Theme } from '@material-ui/core'
import { LayoutStyle } from '../../typings/base'

// const menuWidth = 220

const useStyles = makeStyles<Theme, LayoutStyle>((theme) =>
  createStyles({
    root: {
      minHeight: '100vh',
      position: 'relative',
      zIndex: 2,
    },
    shopContent: {
      position: 'relative',
      zIndex: 1,
      marginTop: -16,
      borderRadius: 0,
      background: theme.palette.common.white,
      [theme.breakpoints.up('sm')]: {
        marginTop: -30,
      },
    },
    shopContentSingle: {
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
      },
    },
    shopContainer: {
      minHeight: '50vh',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    shopPlanner: {
      position: 'relative',
      zIndex: 4, // In order to show date picker above everything
      padding: theme.spacing(1.8),
      // marginTop: -200,
      marginTop: (LayoutStyle) =>
        LayoutStyle?.isZwiterHandCategory ? 0 : -200,
      minHeight: 200,
      boxShadow: theme.shadows[1],
      borderRadius: theme.shape.borderRadius,
      background: theme.palette.common.white,
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(1.8, 3),
        marginTop: (LayoutStyle) =>
          LayoutStyle?.isZwiterHandCategory ? 0 : -theme.spacing(9),
        minHeight: 80,
        marginLeft: 'auto',
        marginRight: 'auto',
        //width: 'max-content',
      },
      '&:empty': {
        '&+ .MuiContainer-root': {
          zIndex: 5,
          marginTop: -theme.spacing(23),
          position: 'relative',
          [theme.breakpoints.up('sm')]: {
            marginTop: -theme.spacing(12.5),
          },
        },
      },
    },
    shopPlannerDad: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: theme.breakpoints.width('lg') + theme.spacing(4),
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: theme.breakpoints.width('lg') + theme.spacing(8),
      },
    },
    shopPlannerColumn: {
      [theme.breakpoints.up('sm')]: {
        minHeight: 50,
      },
      '&:empty': {
        '&+ .MuiContainer-root': {
          [theme.breakpoints.up('sm')]: {
            marginTop: -theme.spacing(5),
          },
        },
      },
    },
    shopPlannerMobileAccordion: {
      marginTop: -27,
      minHeight: 60,
    },
    shopArea: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
    },
    shopAreaSingle: {
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    shopAreaColumn: {
      marginTop: -theme.spacing(22),
      paddingTop: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      position: 'relative',
      zIndex: 5, // VS shopPlanner zIndex
      [theme.breakpoints.up('sm')]: {
        marginTop: -theme.spacing(6),
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    shopIntro: {
      overflow: 'hidden', // Fix horizontal scrolling on mobile
      maxWidth: theme.breakpoints.width('lg'),
      margin: theme.spacing(3, 'auto'),
      '&>div>div>.MuiTypography-root': {
        marginBottom: theme.spacing(1.5),
        '&>span': {
          marginLeft: theme.spacing(1.5),
        },
      },
      '&:empty': {
        margin: 0,
      },
      [theme.breakpoints.down('sm')]: {
        '&>.MuiGrid-root>.MuiGrid-item+.MuiGrid-item': {
          marginTop: -theme.spacing(4.5),
        },
      },
    },
    shopIntroMobileAccordion: {
      overflow: 'hidden', // Fix horizontal scrolling on mobile
      marginBottom: theme.spacing(2),
      '& .MuiAccordion-root': {
        boxShadow: 'none',
        '& .MuiAccordionSummary-root': {
          margin: theme.spacing(1, 0, 0),
          minHeight: 'auto',
          borderRadius: theme.shape.borderRadius,
          backgroundColor: theme.extraColors.greyLight,
        },
        '& .MuiAccordionSummary-content': {
          margin: theme.spacing(1, 0),
        },
      },
      '& .MuiAccordionDetails-root': {
        padding: theme.spacing(2, 1),
      },
    },
    shopMenu: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    shopMenuList: {},
    shopMenuCta: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'row-reverse',
        paddingTop: theme.spacing(2),
      },
    },
    shopMenuRightControls: {
      display: 'flex',
      gap: theme.spacing(2),
    },
    shopMenuSortInput: {
      width: 172,
    },

    shopCard: {
      position: 'relative',
      overflow: 'hidden',
      height: '100%',
      boxShadow: theme.shadows[1],
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.common.white,
      '@media (hover: hover)': {
        '& .glider-next,.glider-prev,.product__variants': {
          display: 'none',
        },
        '&:hover': {
          '& .glider-next,.glider-prev,.product__variants': {
            display: 'flex',
          },
        },
      },
    },
    shopApiContent: {
      '& iframe': {
        width: '100%',
      },
      '& table': {
        width: '100%',
        tableLayout: 'fixed',
        borderCollapse: 'collapse',
        borderColor: theme.extraColors.greyMedium,
        '& td': {
          padding: theme.spacing(0.5, 1),
          borderColor: theme.extraColors.greyMedium,
        },
        '& tr:nth-child(even) td': {
          backgroundColor: theme.extraColors.greyLight,
        },
      },
    },
    shopFilters: {},
    stickyBar: {
      zIndex: 3,
      position: 'sticky',
      transition: theme.transitions.create('top'),
      top: theme.sizes.headerHeight,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    stickyBarScrollUp: {
      transition: theme.transitions.create('top'),
      top: 0,
    },
    stickyBarSticked: {
      '&:before': {
        zIndex: -1,
        content: '""',
        width: '100vw',
        transform: 'translateX(-50%) translateY(0)',
        left: '50%',
        height: '100%',
        position: 'absolute',
        top: 0,
        right: 0,
        background: theme.palette.common.white,
        boxShadow: theme.shadows[1],
      },
    },
    stickyBarTop: {
      boxShadow: 'none',
      '&:before': {
        display: 'none',
      },
    },
    availability: {
      display: 'inline-block',
      top: theme.spacing(2),
      padding: theme.spacing(1, 1.5),
      fontSize: 11,
      borderRadius: 36,
      textAlign: 'center',
    },
    unavailable: {
      color: theme.palette.common.white,
      background: theme.palette.error.light,
    },
    enought: {
      color: theme.palette.ternary.main,
      background: theme.palette.ternary.light,
    },
    available: {
      color: theme.palette.common.white,
      background: theme.palette.success.main,
    },
    noBoxShadow: {
      boxShadow: 'none',
    },
    loader: {
      zIndex: theme.zIndex.tooltip,
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(255,255,255, .5)',
    },
    no_product: {
      textAlign: 'center',
      marginTop: theme.spacing(4),
    },
  })
)

export default useStyles
