import React, { useMemo } from 'react'
import Button from '../Button/Button'
import { Cart } from '@/typings/base'
import { Variant } from '../../interfaces/common/contract'

type SizeButtonProps = {
  variant: Variant
  index: number
  code: string
  type: string
  cart: Cart
  classes: any
  handleChange: (code: string, name: string) => void
}

const SizeButton: React.FC<SizeButtonProps> = ({
  variant,
  index,
  code,
  type,
  cart,
  classes,
  handleChange,
}) => {
  const isSelected = variant.code === code
  const selectedClass = isSelected ? `selected ${classes.selected}` : ''
  const buttonClass = `sizes__btn ${classes.sizeButton} ${selectedClass}`
  const quantityChipClass = `sizes__chip ${classes.quantityChip} ${
    isSelected ? '' : classes.quantityChipSelected
  }`

  const variantInCart = useMemo(() => {
    return cart?.item?.items?.find(
      (el) => el.product.variants[0].code === variant.code
    )
  }, [cart, variant.code])

  const quantity = variantInCart?.quantity

  const onSelect = () => {
    handleChange(variant.code, variant.name)
  }

  return (
    <Button
      size="small"
      data-testid={`sizebuttonAdd-${index}`}
      className={buttonClass}
      onClick={onSelect}
    >
      {type === 'pack' ? variant?.newSize?.name : variant?.size}
      {quantity && <div className={quantityChipClass}>{quantity}</div>}
    </Button>
  )
}

export default SizeButton
