import React from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Price from './Price'
import {
  PRICE_WITHOUT_DISCOUNT,
  DISCOUNTED_PRICE_ONLY,
  DISCOUNTED_PRICE_WITH_DETAILS,
  isSecondHandOnly,
} from '../../utils/constants'
import CMSText from '../Global/CMSText'
import { PrismicGeneralData } from '../../interfaces/common/prismicGeneralConfig'
import { PrismicProduct } from '../../interfaces/common/prismicProduct'
import SecondHandBackInStockAlertButton from '../BackInStockAlert/SecondHandBackInStockAlertButton'
import { Variant } from '../../typings/base'

export const useStyles = makeStyles((theme) =>
  createStyles({
    oldPrice: {
      textDecoration: 'line-through',
    },
    marginLeft: {
      marginLeft: theme.spacing(1),
    },
    promotion: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      borderRadius: theme.spacing(0.5),
      fontSize: '75%',
      marginLeft: theme.spacing(1),
      padding: theme.spacing(0, 0.5),
    },
    prices: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
    pricesOriginal: {
      fontSize: 12,
      paddingLeft: theme.spacing(1),
      borderLeft: `1px solid ${theme.palette.common.black}`,
    },
    startPrice: {
      paddingRight: theme.spacing(0.5),
    },
    startPriceAfter: {
      marginLeft: '4px',
    },
    unavailableContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
  })
)

type TProps = {
  promotions: any
  settings: PrismicGeneralData
  priceMin?: number
  takeBackPrice?: number
  discountPriceFormat?: string
  selectedTakeBackOrRental?: number
  isBuyableProduct: boolean
  isDisplayPercentage?: boolean
  classe: string
  takeback?: any
  selectedQuality?: string
  originalPrice?: number
  productSettings?: PrismicProduct
  takeBackPriceMin?: number
  takeBackAvailability?: boolean
  isSecondHand?: boolean
  avalableForRentalAndSecondHand?: boolean
  isSecondHandTaxon?: boolean
  variants?: Variant[]
}

const ProductPriceFormat = ({
  promotions,
  priceMin: productPrice,
  takeBackPrice,
  selectedTakeBackOrRental,
  settings,
  isBuyableProduct,
  discountPriceFormat,
  isDisplayPercentage = false,
  classe,
  takeback,
  selectedQuality,
  originalPrice,
  productSettings,
  takeBackPriceMin,
  takeBackAvailability,
  isSecondHand,
  avalableForRentalAndSecondHand,
  isSecondHandTaxon,
  variants,
}: TProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const currentPromotion = promotions?.currentPromotion
  const displayFlexPrice = settings?.display_flex_price_a_day
  const isPerMonth = settings?.show_price_per_month

  const priceMin = productPrice * (isPerMonth ? 30 : 1)

  if (isSecondHandTaxon === false) {
    // if we are in rental taxon and we have stock both (rental + 2ndhand)
    isBuyableProduct = avalableForRentalAndSecondHand
      ? !avalableForRentalAndSecondHand
      : false
  }

  return (
    <>
      {(typeof currentPromotion === 'undefined' ||
        currentPromotion === null ||
        isSecondHand) &&
        !selectedTakeBackOrRental &&
        (priceMin !== 0 ? (
          <span className={`${classe}__amount`}>
            <Price
              amount={priceMin}
              dayOrMonth={settings?.day_or_month}
              dayOrMonthShort={settings?.day_or_month_short}
              showDayOrMonth
              isShort={false}
              buyAble={isBuyableProduct}
              displayFlexPrice={displayFlexPrice}
            />
          </span>
        ) : (
          <div>
            <Typography variant="h4" className={'product__unavailable'}>
              <CMSText
                asText
                data={productSettings?.data?.sold_out}
                defaultText={t('texts:packs:victim_of_success')}
              />
            </Typography>
            {isSecondHandOnly &&
              takeback?.data?.take_back_stock_alert_enable && (
                <SecondHandBackInStockAlertButton
                  prismicTakeback={takeback?.data}
                  allVariantsNotInStock={variants}
                  displayTextBeforeButton={false}
                />
              )}
          </div>
        ))}
      {!isSecondHand &&
        currentPromotion !== null &&
        currentPromotion &&
        discountPriceFormat === PRICE_WITHOUT_DISCOUNT &&
        !selectedTakeBackOrRental && (
          <span className={`${classe}__amount`}>
            <Price
              amount={priceMin}
              dayOrMonth={settings?.day_or_month}
              dayOrMonthShort={settings?.day_or_month_short}
              showDayOrMonth
              isShort={false}
              buyAble={isBuyableProduct}
              displayFlexPrice={displayFlexPrice}
            />
          </span>
        )}
      {!isSecondHand &&
        currentPromotion !== null &&
        currentPromotion &&
        discountPriceFormat === DISCOUNTED_PRICE_ONLY &&
        !selectedTakeBackOrRental && (
          <span className={`${classe}__amount`}>
            <Price
              amount={priceMin - (priceMin * currentPromotion) / 100}
              dayOrMonth={settings?.day_or_month}
              dayOrMonthShort={settings?.day_or_month_short}
              showDayOrMonth
              buyAble={isBuyableProduct}
              displayFlexPrice={displayFlexPrice}
            />
          </span>
        )}
      {!isSecondHand &&
        currentPromotion !== null &&
        currentPromotion !== undefined &&
        discountPriceFormat === DISCOUNTED_PRICE_WITH_DETAILS &&
        !selectedTakeBackOrRental && (
          <>
            <span className={clsx(`${classe}__oldPrice`, classes.oldPrice)}>
              <Price
                amount={priceMin}
                dayOrMonth={settings?.day_or_month}
                dayOrMonthShort={settings?.day_or_month_short}
                showDayOrMonth={false}
                buyAble={isBuyableProduct}
                displayFlexPrice={displayFlexPrice}
              />
            </span>
            <span className={clsx(`${classe}__amount`, classes.marginLeft)}>
              <Price
                amount={priceMin - (priceMin * currentPromotion) / 100}
                dayOrMonth={settings?.day_or_month}
                dayOrMonthShort={settings?.day_or_month_short}
                showDayOrMonth
                buyAble={isBuyableProduct}
                displayFlexPrice={displayFlexPrice}
              />
            </span>
            {isDisplayPercentage && (
              <span className={clsx(`${classe}__discount`, classes.promotion)}>
                -{currentPromotion}&nbsp;%
              </span>
            )}
          </>
        )}
      {selectedTakeBackOrRental === 1 && (
        <div
          className={clsx(
            `${classe}__prices`,
            selectedQuality !== '' && classes.prices
          )}
        >
          <>
            {selectedQuality !== '' ? (
              <>
                {!takeBackAvailability && (
                  <span className={classes.startPrice}>
                    <CMSText
                      asText
                      data={takeback?.data?.price_start}
                      defaultText={t('texts:takeBack:price_start')}
                    />
                  </span>
                )}
                <span className={`${classe}__amount`}>
                  <Price
                    amount={takeBackPrice}
                    showDayOrMonth={false}
                    isShort={false}
                    buyAble={isBuyableProduct}
                  />
                </span>
              </>
            ) : (
              <span className={`${classe}__price--start`}>
                <span className={classes.startPrice}>
                  <CMSText
                    asText
                    data={takeback?.data?.price_start}
                    defaultText={t('texts:takeBack:price_start')}
                  />
                </span>
                <span className={`${classe}__amount`}>
                  <Price
                    amount={takeBackPriceMin}
                    showDayOrMonth={false}
                    isShort={false}
                    buyAble={isBuyableProduct}
                  />
                </span>
              </span>
            )}
          </>
          <>
            <span
              className={clsx(
                classes.pricesOriginal,
                `${classe}__price--original`
              )}
            >
              <span className={classes.startPrice}>
                <CMSText
                  asText
                  data={takeback?.data?.original_price}
                  defaultText={t('texts:takeBack:original_price')}
                />
              </span>
              <Price amount={originalPrice} buyAble />

              {process.env.STORE == 'vaude' && (
                <span className={classes.startPriceAfter}>
                  , je inkl. 19 % MwSt.
                </span>
              )}
            </span>
          </>
        </div>
      )}
    </>
  )
}

export default ProductPriceFormat
