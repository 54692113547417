import { Variant } from '../typings/base'

export const getVariantsSkus = (variants: Variant[]): string[] => {
  return Object.values(variants).map((variant) => variant.sku)
}

export const filterSkus = (
  variants: Variant[],
  availableSkus: [],
  sizesToFilter: string[] | []
) => {
  if (sizesToFilter.length === 0) return availableSkus
  const adaptedSkus = adaptSizesToSkus(sizesToFilter, variants)
  if (adaptedSkus.length === 0) return []
  return availableSkus.filter((availableSku) =>
    adaptedSkus.includes(availableSku)
  )
}

export const adaptSizesToSkus = (sizes: string[], variants: Variant[]) => {
  const selectedVariants = Object.values(variants).filter((variant) =>
    sizes.includes(variant.size)
  )
  if (selectedVariants.length === 0) return []
  return selectedVariants.map((selectedVariant) => selectedVariant.sku)
}

export const getCodeBySku = (sku, variants: Variant[]) => {
  const variantBySku = Object.values(variants).filter(
    (variant) => variant.sku === sku
  )
  if (variantBySku.length > 0) return variantBySku[0].code
  return null
}
