import * as prismic from '@prismicio/client'
import { enableAutoPreviews } from '@prismicio/next'
import { setFullLocale } from './utils/utils'

let redis
if (
  typeof window === 'undefined' &&
  process.env.REDIS_URL &&
  process.env.PRISMIC_CACHE_KEY
) {
  import('ioredis').then((Redis) => {
    redis = new Redis.default(process.env.REDIS_URL)
  })
}

const endpoint = process.env.PRISMIC_URL

export const createClient = (config = {}) => {
  const client = prismic.createClient(endpoint, {
    cachedRepository: true,
    cachedRepositoryExpiration: 60 * 15 * 1000, // 15 minutes
  })

  enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  })

  return client
}

export const getSingleDocument = async (client, lang, document) => {
  const cacheKey = process.env.PRISMIC_CACHE_KEY
    ? `prismic:${process.env.PRISMIC_CACHE_KEY}:${document}:${lang}`
    : null

  try {
    if (typeof window !== 'undefined') {
      return await client.getSingle(document, { lang })
    }

    if (!redis || !cacheKey) {
      return await client.getSingle(document, { lang })
    }

    const cachedData = await redis.get(cacheKey)
    if (cachedData) {
      return JSON.parse(cachedData)
    }

    const data = await client.getSingle(document, { lang })
    if (data) {
      await redis.set(cacheKey, JSON.stringify(data), 'EX', 60 * 15)
    }
    return data
  } catch (error) {
    console.error(`Error fetching document "${document}" in "${lang}":`)
    // Éviter de retenter un appel coûteux si une erreur est survenue
    if (redis && cacheKey)
      await redis.set(cacheKey, JSON.stringify(null), 'EX', 60 * 15)
    return null
  }
}

export const getPrismicDocuments = async (documents, locale, previewData) => {
  const client = createClient({ previewData })
  const lang = setFullLocale(locale)

  const data = await Promise.all(
    Object.values(documents).map((document) =>
      getSingleDocument(client, lang, document)
    )
  )

  return Object.fromEntries(
    Object.keys(documents).map((key, index) => [key, data[index]])
  )
}
