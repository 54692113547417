import { ORDER_ITEM_TYPE } from './constants'

// format second hand informations from cart
export const getCartSecondHandItem = (item) => {
  const units = item.units.map((unit) => {
    return {
      productState: unit.productCondition,
      productPuid: unit.puid,
    }
  })
  return {
    sku: item.product.variants[0].sku,
    units: units,
  }
}
// get all informations about 2nde hand products in cart according cartSecondHandItems typing
export const getCartSecondHandItems = (cart) => {
  return cart?.item?.items?.map((item) => getCartSecondHandItem(item)) || []
}

// get second hand informations products in cart for according current sku
export const getCurrentSkuProductInCart = (
  secondHandProductsInCart,
  currentSku
) => {
  return secondHandProductsInCart?.filter(
    (secondHandProduct) => secondHandProduct.sku === currentSku
  )
}

// get type of products in cart (second hand or rental)
export const getProductTypeInCart = (item) => {
  return {
    code: item?.product?.code,
    type: item?.type,
  }
}

export const getProductsTypeInCart = (cart) => {
  return cart?.item?.items?.map((item) => getProductTypeInCart(item))
}

// get type of current product in cart (case of rent and 2nde hand)
export const getCurrentProductTypeInCart = (currentItem, cart) => {
  const productsInCartWithType = getProductsTypeInCart(cart)
  const currentItemCode = currentItem.code

  const currentProductInCart = productsInCartWithType?.find(
    (product) => product.code === currentItemCode
  )
  if (!currentProductInCart) return null
  return currentProductInCart.type
}

// second hand informations to display them on confirmation's page
export const getSecondHandProductInformation = (secondHandProduct) => {
  const units = secondHandProduct.units.map((unit) => {
    return {
      productState: unit.productCondition,
      productPuid: unit.puid,
      productPrice: unit.price,
    }
  })
  return {
    productName: secondHandProduct?.product?.name,
    productImage: secondHandProduct?.product?.images?.[0]?.cachedPath,
    units: units,
  }
}

// there is only second hand in cart
export const isOnlySecondHandInCart = (cart) => {
  return !getProductsTypeInCart(cart)?.find(
    (item) => item.type === ORDER_ITEM_TYPE.RENTAL
  )
}
