import React, { useState, useEffect, useMemo } from 'react'
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { connect } from 'react-redux'
import Button from '../Button/Button'
import QuantitySelector from '../Shop/QuantitySelector'
import { Cart, Product, Variant, shortProduct } from '@/typings/base'
import clsx from 'clsx'
import {
  checkProductAvailability,
  checkStockQuantity,
  getSkus,
  numberToPrice,
  productLowestPrice,
  setFullLocale,
} from '@/utils/utils'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import ImgKitHandler from '../ImgKitHandler/ImgKitHandler'
import CMSText, { prismicText } from '../Global/CMSText'
import { getProductVariantIdByCode } from '@/managers/product/productVariantManager'
import { isSecondHandOnly, isSubscription } from '@/utils/constants'
import { useRouter } from 'next/router'
import BackInStockAlert from '../BackInStockAlert/BackInStockAlert'
import { useTranslation } from 'react-i18next'
import { createClient, getSingleDocument } from '@/prismicio'
import SizeButton from './SizeButton'
import { StockReducerState } from '@/redux/reducers/stockReducer'
import TakeBackSize from './TakeBackSize'
import { useProductStyles } from '../Products/Product.styles'
import { useCart } from '@/hooks/useCart'
import { useTakeback } from '@/hooks/useTakeback'
import {
  createProductsQualityArrayBySize,
  currentQualityInCart,
  getRemainSkusAvailableAfterCheckCart,
  preSelectedSkuAndCondition,
} from '@/utils/takeBack'
import { RootState } from '@/redux/reducers/rootReducer'
import { InferGetStaticPropsType } from 'next'
import { GetMapDispatchTypes } from '@/interfaces/common/redux'
import { PrismicProduct } from '@/interfaces/common/prismicProduct'
import { getTakeBackStock } from '@/redux/actions/takeBack'
import ProductTitle from '../Products/ProductTitle'
import dayjs from 'dayjs'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(0),
      },
    },
    productCard: {
      //display: 'flex',
      position: 'relative',
      minWidth: 320,
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[1],
      margin: theme.spacing(1, 0),
      padding: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: 460,
      },
    },
    image: {
      borderRadius: theme.shape.borderRadius,
    },
    details: {
      flexGrow: 1,
      padding: theme.spacing(0, 0, 0, 0.5),
    },
    closeButton: {
      display: 'inline-flex',
      justifyContent: 'flex-end',
      backgroundColor: theme.extraColors.greyLight,
    },
    cross: {
      cursor: 'pointer',
      height: 40,
      width: 40,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 20,
      margin: theme.spacing(1),
    },
    productName: {
      fontWeight: 400,
      fontSize: 14,
      marginTop: theme.spacing(1),
    },
    sizes: {
      marginTop: theme.spacing(1),
      '& .MuiTypography-root': {
        fontWeight: 400,
      },
    },
    sizes__list: {
      padding: theme.spacing(0.25),
      borderRadius: theme.spacing(1),
      textAlign: 'left',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridTemplateRows: '1fr 1fr 1fr',
      columnGap: '0.5rem',
      justifyContent: 'center',
      width: '100%',
    },
    withoutStock: {
      height: '6px',
      width: '6px',
      backgroundColor: '#B5B5B5',
      borderRadius: '50%',
      position: 'relative',
      top: '30%',
      left: '82%',
      zIndex: 1,
    },
    withStock: {
      height: '6px',
      width: '6px',
      backgroundColor: '#47E169',
      borderRadius: '50%',
      position: 'relative',
      top: '30%',
      left: '82%',
      zIndex: 1,
    },
    addButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-end',
      },
      marginTop: theme.spacing(1),
    },
    returnButton: {
      justifyContent: 'flex-start',
    },
    sizeButton: {
      backgroundColor: theme.extraColors.greyLight,
      width: '100%',
      '&:hover': {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      '&.sold-out': {
        opacity: 0.5,
        color: theme.palette.error.main,
      },
      '& span': {
        whiteSpace: 'nowrap',
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.5, 1),
        margin: theme.spacing(0.25),
      },
    },
    selected: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.black,
      '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
      },
    },
    quantSelector: {
      marginRight: theme.spacing(2),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
    },
    quantityChip: {
      position: 'absolute',
      top: -5,
      right: -5,
      minWidth: 20,
      height: 20,
      lineHeight: 1,
      borderRadius: 10,
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.secondary.main,
      padding: theme.spacing(0.5),
      color: theme.palette.common.white,
      marginLeft: theme.spacing(2),
    },
    quantityChipSelected: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    titleInHeader: {
      padding: theme.spacing(1),
    },
    selectContainer: {
      position: 'relative',
    },
    sizeSelector: {
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(0.5, 2, 0.5, 1),
      margin: theme.spacing(0.5),
      backgroundColor: theme.extraColors.greyLight,
      minWidth: '84%',
      color: theme.palette.common.black,
      height: 40,
      fontWeight: 700,
      border: 'none',
    },
    quantitySelected: {
      position: 'absolute',
      top: 0,
      right: 'auto',
      left: `calc(84% - ${theme.spacing(0.5)}px)`,
      margin: 0,
    },
    availability: {
      position: 'absolute',
      zIndex: 2,
      top: '50%',
      left: '50%',
      transform: 'translate3D(-50%, -50%, 0)',
      padding: theme.spacing(1, 1.5),
      fontSize: 11,
      borderRadius: 36,
      textAlign: 'center',
    },
    unavailable: {
      color: theme.palette.common.white,
      background: theme.extraColors.greyMedium,
    },
    valign: {
      display: 'flex',
      minHeight: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    media: {
      position: 'relative',
    },
    badge: {
      zIndex: 2,
      position: 'absolute',
      top: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '90%',
      fontWeight: 700,
      lineHeight: 1,
      padding: '.25em',
      minWidth: '1.75em',
      height: '1.75em',
      borderRadius: '1.75em',
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  })
)

const mapStateToProps = (state: RootState) => ({
  stores: state.stores,
  takeBackLP: state.takeBack,
  promotions: state.promotions,
})

const mapDispatchToProps = {
  getTakeBackStock: getTakeBackStock,
}

type Tprops = {
  product: Product
  productsQty?: number
  type: string
  stock: StockReducerState
  text?: any
  handlePackSelection?: any
  onQuantityChange?: (code: string, name: string, quantity: number) => void
  cart: Cart
  thumbRatio: string
  thumbWidth?: number
  settings: any
  currentUrl?: string
  stores: any
  isSecondHandTaxon: boolean
  productSettings: PrismicProduct
  takeback: any
  addedToCart: () => void
} & ReturnType<typeof mapStateToProps> &
  InferGetStaticPropsType<typeof getServerSideProps> &
  GetMapDispatchTypes<typeof mapDispatchToProps>

const SizeCard = ({
  product,
  productsQty,
  onQuantityChange,
  handlePackSelection,
  stock,
  type,
  cart,
  text,
  thumbRatio,
  thumbWidth,
  settings,
  currentUrl,
  stores,
  singleProductRes,
  productSettings,
  takeBackLP,
  takeback,
  isSecondHandTaxon,
  getTakeBackStock,
  addedToCart,
  promotions,
}: Tprops) => {
  const classes = useStyles()
  const router = useRouter()
  const { t } = useTranslation()

  const [code, setCode] = useState('')
  const [name, setName] = useState('')
  const [cartQuantity, setCartQuantity] = useState(0)
  const [choosenQuantity, setChoosenQuantity] = useState(
    type === 'pack' ? productsQty : 1
  )
  const [maxItemStock, setMaxItemStock] = useState(null)
  const [availability, setAvailability] = useState(true)
  const [hasInStock, setHasInStock] = useState(-1)
  const productClasses = useProductStyles()

  const [noMoreStockAvailable, setNoMoreStockAvailable] = useState(false)
  const [showQualities, setShowQualities] = useState(true)
  const [selectedQuality, setSelectedQuality] = useState('')
  const [currentSizeQualities, setCurrentSizeQualities] = useState([])
  const [currentVariantCode, setCurrentVariantCode] = useState('')
  const [currentSku, setCurrentSku] = useState('')
  const [
    remainSecondHandSkusAfterCheckCart,
    setRemainSecondHandSkusAfterCheckCart,
  ] = useState([])
  const [minPrice, setMinPrice] = useState(0)
  const [takeBackAvailability, setTakeBackAvailability] = useState(false)
  const [isBuyableProduct, setIsBuyableProduct] = useState(false)
  const [originalPrice, setOriginalPrice] = useState(0)
  const [takeBackPrice, setTakeBackPrice] = useState(0)
  const [takeBackPriceMin, setTakeBackPriceMin] = useState(0)
  const [selectedTakeBackOrRental, setSelectedTakeBackOrRental] = useState(0)
  const [currentItem, setCurrentItem] = useState(product)
  const { trackEvent } = useMatomo()
  const { productCheckState } = useTakeback()
  const states = productCheckState(takeback?.data)
  const { addProductToCart } = useCart()

  const start = dayjs(cart?.item?.startLeasingDate, 'DD/MM/YYYY')
  const end = dayjs(cart?.item?.endLeasingDate, 'DD/MM/YYYY')
  const dayRange = end.diff(start, 'day') + 1

  const selectVariantLimit =
    settings?.modal_size_select_display_limit &&
    settings?.modal_size_select_display_limit !== null
      ? settings.modal_size_select_display_limit
      : 6
  const variants = []
  Object.keys(product?.variants)?.map((variantIndex) => {
    variants.push(product?.variants[variantIndex])
  })
  const baseUrl =
    typeof window !== 'undefined' && window.location.origin
      ? window.location.origin
      : ''

  const warehouseId = stores?.selectedStore?.warehouseId
    ? stores?.selectedStore?.warehouseId
    : process.env.CUSTOM_WAREHOUSE
    ? process.env.CUSTOM_WAREHOUSE
    : null

  const discountPriceFormat = settings?.discounted_price_display_format
  const displayFlexPrice = settings?.display_flex_price_a_day
  const isDisplayTotalRentPrice =
    productSettings?.data?.display_total_rental_price
  const currentPromotion = promotions?.currentPromotion

  const currentFullUrl = baseUrl + router.asPath

  //For now the stock alert is made only for sub stores
  const showStockAlert = isSubscription
  const productLayout = productSettings?.data?.product_layout
  const addFromPack = settings?.only_add_with_pack
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const secondHandStock = useMemo(
    () => stock?.stock?.filter((skuStock) => !!skuStock?.secondHand),
    [stock?.stock]
  )

  const handleSizeSelected = (sku) => {
    const qualitiesArray = createProductsQualityArrayBySize(
      takeBackLP?.takeBackStock,
      sku,
      states,
      cart
    )

    setCurrentSizeQualities(qualitiesArray)
    setCurrentVariantCode(
      Object.values(product.variants)?.find((el: Variant) => el.sku === sku)
        .code
    )
    setShowQualities(true)
    setSelectedQuality('')
    setCurrentSku(sku)
  }

  const handleStateSelected = (quality) => {
    setSelectedQuality(quality?.quality)

    const numberInCart = currentQualityInCart(quality.quality, currentSku, cart)

    const item = { ...product }
    const images = []
    images.push(...quality?.products[numberInCart]?.images)
    images.push(...item.images)
    item.images = images
    setCurrentItem(item)

    const currentPricesByCondition = Object.values(product.variants)?.find(
      (el) => el.sku === currentSku
    )?.resalePrice
    const price = currentPricesByCondition[quality.quality]
    setTakeBackPrice(price)
  }

  useEffect(() => {
    // reset quantity when cart change (ie if a product is delete, it update the quantity)
    const cartQuantity =
      cart &&
      cart.item &&
      cart.item.items.length > 0 &&
      typeof cart.item.items.find(
        (el) => el.product.variants[0].code === code
      ) !== 'undefined'
        ? cart.item.items.find((el) => el.product.variants[0].code === code)
            .quantity
        : 0
    setCartQuantity(cartQuantity)
    if (cartQuantity !== 0) {
      setChoosenQuantity(cartQuantity)
    }
  }, [cart.item])

  useEffect(() => {
    const loadProductData = async () => {
      const SKUArray = getSkus(product)
      let takeBackStockPreLoaded
      if (SKUArray.length > 0) {
        const data = {
          lizeeSKUs: SKUArray,
        }
        const res = await getTakeBackStock(data)
        takeBackStockPreLoaded = res.response
      }

      const ChosenSizeAndCondition = preSelectedSkuAndCondition(
        secondHandStock,
        product,
        takeBackStockPreLoaded,
        states,
        cart,
        setNoMoreStockAvailable
      )
      if (
        ChosenSizeAndCondition?.chosenSku !== null &&
        ChosenSizeAndCondition.chosenQuality !== undefined
      ) {
        setCurrentSku(ChosenSizeAndCondition?.chosenSku)
        setCurrentSizeQualities(ChosenSizeAndCondition?.qualitiesArray)
        setCurrentVariantCode(ChosenSizeAndCondition?.chosenCode)
        setOriginalPrice(ChosenSizeAndCondition?.originalPrice)
        setTakeBackPrice(ChosenSizeAndCondition?.currentPrice)
        setSelectedQuality(
          ChosenSizeAndCondition.chosenQuality !== null
            ? (ChosenSizeAndCondition.chosenQuality as string)
            : ChosenSizeAndCondition?.qualitiesArray.find(
                (quality) => !quality.disabled
              ).quality
        )
        setCurrentItem(ChosenSizeAndCondition?.item)
        const currentProductSkus = Object.values(product.variants).map(
          (variant) => variant.sku
        )
        const secondHandStockForThisProduct = secondHandStock.filter(
          (skuStock) => currentProductSkus.includes(skuStock.lizeeSKU)
        )
        setRemainSecondHandSkusAfterCheckCart(
          getRemainSkusAvailableAfterCheckCart(
            cart,
            secondHandStockForThisProduct
          )
        )
      }
    }
    loadProductData()
  }, [product])

  useEffect(() => {
    const priceArray = []
    const originalPriceArray = []
    const takeBackPriceArray = []
    let takeBackMinPriceArray = []

    if (product && stock?.stock && stock?.stock?.length > 0) {
      // Handle rental availability
      const productAvailability = checkProductAvailability(stock, product)
      setAvailability(productAvailability.available)
      // Handle second hand availability
      setTakeBackAvailability(productAvailability.secondHandAvailable)

      // Handle Prices
      product?.variants.forEach((variant) => {
        if (!variant?.purchase) {
          priceArray.push(variant?.price?.current)
          setIsBuyableProduct(false)
        } else {
          priceArray.push(variant?.purchasePrice)
          setIsBuyableProduct(true)
        }
        originalPriceArray.push(variant?.originalPrice?.current)
      })
      // The price displayed on loading is the lowest price between all the variants
      setMinPrice(Math.min(...priceArray))
      // Same logic for the original price
      setOriginalPrice(Math.min(...originalPriceArray))
      // Same logic for second hand prices with takeBackAvailability in mind
      if (productAvailability.secondHandAvailable) {
        product.variants.forEach((variant) => {
          if (variant?.resalePrice) {
            Object.values(variant?.resalePrice).forEach((price) => {
              takeBackPriceArray.push(price)
            })
          }
        })
      }
      // find lowest 2nd hand price available
      if (productAvailability.secondHandAvailable) {
        takeBackMinPriceArray = productLowestPrice(product, takeBackLP)
      }
      setTakeBackPriceMin(Math.min(...takeBackMinPriceArray))
    }
  }, [product, stock, takeBackLP])

  useEffect(() => {
    let isAvailable = false
    isAvailable = checkProductAvailability(stock, product).available
    setAvailability(isAvailable)

    if (variants && variants.length <= 1) {
      setCode(variants[0].code)
      const variant = product.variants[0]
      let inStock = 0
      if (type === 'pack') {
        inStock = checkStockQuantity(stock, product.variants[0])
      } else {
        inStock = checkStockQuantity(stock, variant)
      }
      setHasInStock(inStock)
    }
  }, [])

  const handleSelect = (event) => {
    handleChange(event.target.value, event.target.value)
  }

  const handleChange = (code, name) => {
    const variant = product.variants.find((variant) => variant.code === code)
    setCode(code)
    setName(name)
    let inStock = 0
    const cartQuantity =
      cart &&
      cart.item &&
      cart.item.items.length > 0 &&
      typeof cart.item.items.find(
        (el) => el.product.variants[0].code === code
      ) !== 'undefined'
        ? cart.item.items.find((el) => el.product.variants[0].code === code)
            .quantity
        : 0
    let maxItemInStock

    if (type === 'pack') {
      if (product?.variants?.length > 0) {
        const id = product.variants.findIndex(
          (variant) => variant.code === code
        )
        maxItemInStock = product.variants[id]?.maxStock
        setMaxItemStock(maxItemInStock ? maxItemInStock - cartQuantity : null)
        inStock = checkStockQuantity(stock, product.variants[id])
      } else {
        inStock = checkStockQuantity(stock, variant)
        maxItemInStock = variant?.maxStock
        setMaxItemStock(maxItemInStock || null)
      }
    } else {
      inStock = checkStockQuantity(stock, variant)
      maxItemInStock = variant?.maxStock
      setMaxItemStock(maxItemInStock || null)
    }

    setHasInStock(inStock)
    setCartQuantity(cartQuantity)
    if (type === 'pack') {
      if (maxItemInStock === null || cartQuantity < maxItemInStock) {
        handlePackSelection(
          product.isSimpleProduct,
          product.code,
          code,
          choosenQuantity
        )
      } else {
        handlePackSelection(product.isSimpleProduct, product.code, code, 0)
      }
      const currentVariant = product.variants.find(
        (variant) => variant.code === code
      )
      trackEvent({
        category: 'Products',
        action: `Size selected`,
        name: `${
          currentVariant.optionValues?.length > 0 &&
          currentVariant?.optionValues[0]?.code
            ? currentVariant?.optionValues[0]?.code
            : currentVariant.name
        }`,
      })
    } else {
      trackEvent({
        category: 'Products',
        action: `Size selected`,
        name: `${variant?.size}`,
      })
    }
  }

  const changeQuantity = (quantity: number) => {
    // get stock
    let inStock = 0
    if (type === 'pack') {
      const id = getProductVariantIdByCode(product?.variants, code)
      inStock = checkStockQuantity(stock, product.variants[id])
      if (quantity <= inStock) {
        trackEvent({
          category: 'Products',
          action: `Quantity changed`,
          name: `product ${product?.name} from ${currentUrl}`,
          value: quantity,
        })
      }
    } else {
      const variant = product.variants.find((variant) => variant.code === code)
      inStock = checkStockQuantity(stock, variant)
      if (quantity <= inStock) {
        trackEvent({
          category: 'Products',
          action: `Quantity changed`,
          name: `product ${product?.name} from ${currentUrl}`,
          value: quantity,
        })
      }
    }

    setHasInStock(inStock)

    // limit maximum product a client can select
    if (quantity <= inStock) {
      setChoosenQuantity(quantity)
      if (type === 'pack') {
        handlePackSelection(
          product.isSimpleProduct,
          product.code,
          code,
          quantity
        )
      }
    }
  }

  const handleAdd = () => {
    const quantity = settings?.has_quantity_selector
      ? choosenQuantity
      : cartQuantity + 1

    onQuantityChange(code, name, quantity)

    const price = numberToPrice(product?.variants[0]?.price?.current)

    trackEvent({
      category: 'Add to Cart',
      action: `${name}`,
      name: `${currentUrl}`,
      value: price,
    })
  }

  useEffect(() => {
    let isAvailable = false
    isAvailable = checkProductAvailability(stock, product).available
    if (product.variants.length === 1 && isAvailable) {
      //ie there is only one variant
      handlePackSelection(
        product.isSimpleProduct,
        product.code,
        product.variants[0].code,
        choosenQuantity
      )
    }
  }, [availability])

  useEffect(() => {
    if (!takeBackAvailability && availability) {
      setSelectedTakeBackOrRental(0)
    }
    if (isSecondHandOnly || isSecondHandTaxon) setSelectedTakeBackOrRental(1)
  }, [isSecondHandOnly, isSecondHandTaxon, takeBackAvailability, availability])

  //This part of code is made to identify the current short product selected
  //and its unique variant it is needed for the stockAlert component
  const currentVariantSelected = variants.find(
    (variant) => variant.code === code
  )
  const currentVariantSKU = currentVariantSelected?.sku
  const currentVariantQuantity = stock?.stock.find(
    (unit) => unit.lizeeSKU === currentVariantSKU
  )?.quantity
  const shortProduct: shortProduct[] = currentVariantSKU
    ? [
        {
          sku: currentVariantSKU,
          quantity: currentVariantQuantity,
        },
      ]
    : undefined

  const sizeStockColor = (variant: Variant) => {
    const sku = stock?.stock?.find((varSku) => varSku.lizeeSKU === variant.sku)

    return `sizes__btn-withoutStock ${
      sku?.quantity === 0 ? classes.withoutStock : classes.withStock
    }`
  }

  return (
    <>
      <div className={classes.root}>
        <article className={`size-card ${classes.productCard}`}>
          {!availability && !takeBackAvailability && variants.length <= 1 && (
            <Typography
              noWrap
              component="p"
              className={clsx(
                'size-card__soldout',
                classes.availability,
                classes.unavailable
              )}
              variant="body2"
            >
              <CMSText
                data={text?.sold_out}
                defaultText={t('texts:shop:victim_of_success')}
              />
            </Typography>
          )}
          <div className={classes.details}>
            <Grid container spacing={2} justify={'center'}>
              <Grid item xs={4} className={classes.media}>
                {!settings?.has_quantity_selector && productsQty > 1 && (
                  <div className={clsx('badge', classes.badge)}>
                    x{productsQty}
                  </div>
                )}
                <div data-ratio={thumbRatio}>
                  <ImgKitHandler
                    imagePaths={currentItem?.images[0]}
                    settings={{
                      width: thumbWidth,
                      ar: thumbRatio?.substring(3),
                      quality: 50,
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={8} className={classes.valign}>
                {!isSecondHandTaxon ? (
                  <>
                    <div className={`size-card__top`}>
                      <Typography
                        className={`size-card__title ${classes.productName}`}
                        variant="h4"
                        component="div"
                      >
                        {product.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className={'size-card__status'}
                      >
                        {code !== '' &&
                          hasInStock === 0 &&
                          prismicText(
                            settings?.back_soon,
                            t('texts:general:stock_texts:back_soon')
                          )}
                        {code !== '' &&
                          hasInStock > 0 &&
                          hasInStock < 6 &&
                          prismicText(
                            settings?.few_products_left,
                            t('texts:general:stock_texts:few_products_left')
                          )}
                        {code !== '' &&
                          hasInStock > 5 &&
                          prismicText(
                            settings?.in_stock,
                            t('texts:general:stock_texts:in_stock')
                          )}
                      </Typography>
                      {code !== '' &&
                        maxItemStock !== null &&
                        cartQuantity + 1 > maxItemStock && (
                          <Typography
                            variant="body2"
                            component="p"
                            color={'error'}
                          >
                            {prismicText(
                              text?.max_item_in_cart,
                              t('texts:shop:max_item_in_cart')
                            )}
                          </Typography>
                        )}
                    </div>
                    <div className={`size-card__bottom`}>
                      <div className={`sizes ${classes.sizes}`}>
                        {variants && variants.length > 1 && availability && (
                          <Typography variant="h6" component="div">
                            <CMSText
                              asText
                              data={
                                type === 'pack'
                                  ? settings?.data?.sizes
                                  : settings?.sizes
                              }
                              defaultText={t('texts:general:sizes')}
                            />
                          </Typography>
                        )}
                      </div>
                      <div className={`sizes__list ${classes.sizes__list}`}>
                        {variants &&
                          variants.length > 1 &&
                          variants.length < selectVariantLimit + 1 &&
                          variants
                            ?.sort((a, b) => a.position - b.position)
                            ?.map((variant, index) => {
                              return (
                                <div key={index}>
                                  <div className={sizeStockColor(variant)} />
                                  <SizeButton
                                    classes={classes}
                                    variant={variant}
                                    index={index}
                                    code={code}
                                    type={type}
                                    cart={cart}
                                    handleChange={handleChange}
                                  />
                                </div>
                              )
                            })}
                      </div>
                      {variants && variants.length > selectVariantLimit && (
                        <div
                          className={clsx(
                            'sizes__select-container',
                            classes.selectContainer
                          )}
                        >
                          <select
                            value={name}
                            className={clsx(
                              'sizes__select',
                              classes.sizeSelector
                            )}
                            onChange={handleSelect}
                          >
                            <option value={''} selected>
                              {t('texts:packs:select')}
                            </option>
                            {variants?.map((variant, index) => {
                              let outOfStock = false
                              if (
                                stock?.stock?.find(
                                  (varSku) => varSku.lizeeSKU === variant.sku
                                )?.quantity === 0
                              ) {
                                outOfStock = true
                              }
                              return (
                                <>
                                  <option
                                    key={index}
                                    value={variant?.code}
                                    disabled={outOfStock}
                                  >
                                    {type === 'pack'
                                      ? variant?.newSize?.name
                                      : variant?.size}
                                  </option>
                                </>
                              )
                            })}
                          </select>
                          {cart &&
                            cart.item?.items.length > 0 &&
                            typeof cart?.item?.items?.find(
                              (el) => el.product.variants[0].code === code
                            ) !== 'undefined' &&
                            cart?.item?.items?.find(
                              (el) => el.product.variants[0].code === code
                            ).quantity > 0 && (
                              <div
                                className={clsx(
                                  'sizes__select-chip',
                                  classes.quantityChip,
                                  classes.quantitySelected
                                )}
                              >
                                {cart &&
                                  cart.item?.items.length > 0 &&
                                  typeof cart?.item?.items.find(
                                    (el) => el.product.variants[0].code === code
                                  ) !== 'undefined' &&
                                  cart?.item?.items.find(
                                    (el) => el.product.variants[0].code === code
                                  ).quantity}
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <ProductTitle
                      isModal
                      productLayout={productLayout}
                      isMobile={isMobile}
                      currentItem={currentItem}
                      addFromPack={addFromPack}
                      promotions={promotions}
                      minPrice={minPrice}
                      takeBackPrice={takeBackPrice}
                      main={settings}
                      isBuyableProduct={isBuyableProduct}
                      discountPriceFormat={discountPriceFormat}
                      selectedTakeBackOrRental={selectedTakeBackOrRental}
                      takeback={takeback}
                      displayFlexPrice={displayFlexPrice}
                      isDisplayTotalRentPrice={isDisplayTotalRentPrice}
                      productSettings={productSettings}
                      dayRange={dayRange}
                      currentPromotion={currentPromotion}
                      selectedQuality={selectedQuality}
                      originalPrice={originalPrice}
                      takeBackPriceMin={takeBackPriceMin}
                      takeBackAvailability={takeBackAvailability}
                      isDisplayedPrice={
                        settings?.data?.is_product_price_displayed
                      }
                      isSecondHand={isSecondHandTaxon}
                    />
                    <div className={productClasses.selectors}>
                      <TakeBackSize
                        t={t}
                        products={currentItem}
                        handleChangeCurrentItem={null}
                        noMoreStockAvailable={noMoreStockAvailable}
                        cart={cart}
                        addProductToCart={addProductToCart}
                        settings={settings}
                        takeback={takeback}
                        currentItem={currentItem}
                        selectedQuality={selectedQuality}
                        handleStateSelected={handleStateSelected}
                        handleSizeSelected={handleSizeSelected}
                        showQualities={showQualities}
                        sku={currentSku}
                        currentSizeQualities={currentSizeQualities}
                        currentVariantCode={currentVariantCode}
                        productSettings={productSettings?.data}
                        currentQualityInCart={currentQualityInCart}
                        setSelectedQuality={setSelectedQuality}
                        availableSkus={remainSecondHandSkusAfterCheckCart}
                        addedToCart={addedToCart}
                      />
                    </div>
                  </>
                )}
              </Grid>
            </Grid>
          </div>
        </article>
        {hasInStock === 0 && showStockAlert ? (
          <BackInStockAlert
            singleProductPrismicData={singleProductRes?.data}
            products={shortProduct}
            url={currentFullUrl}
            warehouseId={warehouseId}
          />
        ) : (
          <div className={classes.addButton}>
            {availability &&
              ((settings?.has_quantity_selector && type === 'pack') ||
                (type === 'product' && settings?.has_quantity_selector)) && (
                <div className={classes.quantSelector}>
                  <QuantitySelector
                    value={choosenQuantity}
                    disable={code === '' || !availability}
                    handleChange={changeQuantity}
                    min={0}
                    max={maxItemStock}
                  />
                </div>
              )}
            {type === 'product' && !isSecondHandOnly && (
              <Button
                variant="contained"
                size={'small'}
                color="secondary"
                data-testid={`sizeValidbutton`}
                id="sizeValidbutton"
                disabled={
                  cartQuantity + 1 === 0 ||
                  code === '' ||
                  (maxItemStock !== null && cartQuantity + 1 > maxItemStock)
                }
                onClick={handleAdd}
              >
                {prismicText(
                  text?.data?.product_size_modal_cta_add,
                  t('texts:shop:choose')
                )}
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export async function getServerSideProps({ locale, previewData }) {
  const client = createClient({ previewData })
  const lang = setFullLocale(locale)

  const singleProductRes = (await getSingleDocument(
    client,
    lang,
    'single_product'
  )) as PrismicProduct

  return {
    props: { singleProductRes },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SizeCard)
